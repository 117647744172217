<template>
<div class="wrap1160">
  <div id="search">
    <div class="search_box">
      <h2>{{$t(parent.nameTag)}}</h2>
      <form v-on:submit.prevent="itemSearchStore.searchItems($router, null)">
        <div class="search-area bgLGray">
          <div class="search-area-block">
            <dl class="pr-2">
              <dt>{{$t("ITEM_SEARCH_MAKER_LABEL")}}</dt>
              <dd>
                  <div class="select-style">
                    <SelectMaker
                      :model="filter.maker"
                      :options="selectMakers || []"
                      :firstLabel="''"
                      @onChangeModelValue="(val) => {filter.maker=val}"
                    />
                  </div>
              </dd>
            </dl>
            <dl class="pl-2">
              <dt>{{$t("ITEM_SEARCH_BODY_TYPE_LABEL")}}</dt>
              <dd>
                  <div class="select-style">
                    <select v-model="filter.body_type">
                      <option :value="null"></option>
                      <option v-for="typ in selectBodyTypes" :value="typ.value" :key="typ.value">{{typ.label}}</option>
                    </select>
                  </div>
              </dd>
            </dl>
          </div>
          <div class="search-area-block">
            <dl class="pr-2">
              <dt>{{$t("ITEM_SEARCH_CUBIC_CAPACITY_LABEL")}}</dt>
              <dd>
                <div class="pst-year">
                  <div class="select-style">
                    <select v-model="filter.startCubicCapacity">
                      <option :value="null"></option>
                      <option v-for="cubic in selectCubics" :value="cubic" :key="cubic">{{cubic}}</option>
                    </select>
                  </div>
                  <span>～</span>
                  <div class="select-style">
                    <select v-model="filter.endCubicCapacity">
                      <option :value="null"></option>
                      <option v-for="cubic in selectCubics.filter(cu => !filter.startCubicCapacity || cu >= filter.startCubicCapacity)" :value="cubic" :key="cubic">{{cubic}}</option>
                    </select>
                  </div>
                </div>
              </dd>
            </dl>
            <dl class="pl-2">
              <dt>{{$t("ITEM_SEARCH_MILEAGE_LABEL")}}</dt>
              <dd>
                <div class="pst-year">
                  <div class="select-style">
                    <select v-model="filter.startMileage">
                      <option :value="null"></option>
                      <option v-for="cubic in selectMileages" :value="cubic" :key="cubic">{{cubic}}</option>
                    </select>
                  </div>
                  <span>～</span>
                  <div class="select-style">
                    <select v-model="filter.endMileage">
                      <option :value="null"></option>
                      <option v-for="cubic in selectMileages.filter(cu => !filter.startMileage || cu >= filter.startMileage)" :value="cubic" :key="cubic">{{cubic}}</option>
                    </select>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
          <div class="search-area-block">
            <!-- <dl class="pr-2">
              <dt>{{$t("ITEM_SEARCH_FIRST_INSPECTION_LABEL")}}</dt>
              <dd>
                <div class="pst-year">
                  <div class="select-style">
                    <select v-model="filter.startFirstInspection">
                      <option :value="null"></option>
                      <option v-for="cubic in selectYears" :value="cubic" :key="cubic">{{cubic}}</option>
                    </select>
                  </div>
                  <span>～</span>
                  <div class="select-style">
                    <select v-model="filter.endFirstInspection">
                      <option :value="null"></option>
                      <option v-for="cubic in selectYears.filter(cu => !filter.startFirstInspection || cu >= filter.startFirstInspection)" :value="cubic" :key="cubic">{{cubic}}</option>
                    </select>
                  </div>
                </div>
              </dd>
            </dl> -->
            <dl class="pl-2">
              <dt>{{$t("ITEM_SEARCH_AREA_LABEL")}}</dt>
              <dd>
                <div class="select-style">
                  <select v-model="filter.startArea">
                    <option :value="null"></option>
                    <option v-for="area in selectAreas" :value="area.value" :key="area.value">{{area.label}}</option>
                  </select>
                </div>
              </dd>
            </dl>
            <dl class="pl-2"></dl>
          </div>
          <div class="search-area-block">
            <dl>
              <dt>{{$t("ITEM_SEARCH_BIKE_TYPE_LABEL")}}</dt>
              <dd><input type="text" style="background-color: white;" v-model="filter.bike_type" :placeholder="$t('ITEM_SEARCH_KEYWORD_PLACEHOLDER')"></dd>
            </dl>
          </div>
          <div class="search-area-block">
            <dl>
              <dd>
                <ul class="pst-cek">
                  <li v-if="$cookies.get($define.LOCAL_STORE_LABEL.SESSION_TOKEN)!=null">
                    <label>
                      <input type="checkbox" class="checkbox-input" v-model="filter.favorite">
                      <span class="checkbox-parts">{{$t("ITEM_SEARCH_FAVORITE_LABEL")}}</span>
                    </label>
                  </li>
                  <li v-if="$cookies.get($define.LOCAL_STORE_LABEL.SESSION_TOKEN)!=null && $route.name!=$define.PAGE_NAMES.STOCK_LIST_PAGE_NAME">
                    <label>
                      <input type="checkbox" class="checkbox-input" v-model="filter.bidding">
                      <span class="checkbox-parts">{{$t("ITEM_SEARCH_BIDDING_LABEL")}}</span>
                    </label>
                  </li>
                  <li v-if="$cookies.get($define.LOCAL_STORE_LABEL.SESSION_TOKEN)!=null && $route.name==$define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME">
                    <label>
                      <input type="checkbox" class="checkbox-input" v-model="filter.exceedingLowestPrice">
                      <span class="checkbox-parts">{{$t("ITEM_SEARCH_EXCEEDING_THE_LOWEST_BID_LABEL")}}</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="checkbox" class="checkbox-input" v-model="filter.unSoldOut">
                      <span class="checkbox-parts">{{$t("ITEM_SEARCH_SOLD_OUT_LABEL")}}</span>
                    </label>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
          <a class="btnBsc-Black btn-search" @click="btnSearchClick">{{$t("ITEM_SEARCH_SEARCH_BUTTON_LABEL")}}</a>
        </div>
      </form>
    </div>

    <div class="search_result">
      <dl>
        <dt>{{$t("ITEM_SEARCH_CONDITIONS_LABEL")}}</dt>
        <dd>
          <span v-for="(row, index) in getFiltered" :key="index">{{row}}</span>
        </dd>
      </dl>
      <dl>
        <dt>{{$t("ITEM_SEARCH_SEARCH_RESULTS_LABEL")}}</dt>
        <dd>{{itemsCount}}{{$t("ITEM_SEARCH_SEARCH_RESULTS_NUMBER_SUBJECT_LABEL")}}</dd>
      </dl>
    </div>
  </div>

  <div id="result">
    <div class="sort">
      <form v-on:submit.prevent="">
        <div class="btnSort">
          <div class="btn_sort-price" v-if="$cookies.get($define.LOCAL_STORE_LABEL.SESSION_TOKEN)!=null">
            <p v-if="$route.name==$define.PAGE_NAMES.TENDER_LIST_PAGE_NAME">{{$t("DETAIL_LOWEST_BID_ACCEPT_PRICE_LABEL")}}</p>
            <p v-else>{{$t("ITEM_SEARCH_CURRENT_PRICE_SORT_LABEL")}}</p>
            <button :class="{'active' : currentPriceSort === 'asc'}" @click="filter.currentPriceSort = 'asc'; updateFilter()">▲</button>
            <button :class="{'active' : currentPriceSort === 'desc'}" @click="filter.currentPriceSort = 'desc'; updateFilter()">▼</button>
          </div>
          <div class="btn_sort-nmb">
            <p>{{$t("ITEM_SEARCH_RESULTS_LIMIT_LABEL")}}</p>
            <button v-for="per in limitPerPages" :key="per" :class="{'active' : filter.limit == per}" @click="filter.limit = per; updateFilter()">{{per}}{{$t("ITEM_SEARCH_SEARCH_RESULTS_SUBJECT_LABEL")}}</button>
          </div>
        </div>
      </form>
    </div>

    <ul class="machine_list">
      <ItemRow
        v-for="(item) in items"
        :item="item"
        :parent="$data"
        :grandfather="parent"
        :updateViewAfterBid="itemSearchStore.updateViewAfterBid"
        :errorMessages="[]"
        :favorite="favorite"
        :key="item.exhibition_item_no"
        :getPriceIncludeTax="itemSearchStore.getPriceIncludeTax"
        @bidConfirm="openBidConfirmDialog"
        @cancelBidDialog="openCancelBidDialog"
      />
    </ul>

    <a v-if="isMoreLimit" class="btnBsc-Black btn-more" @click="btnMoreClick">{{$t('MY_PAGE_SHOW_MORE_BUTTON_LABEL')}}</a>
  </div>
  <v-dialog v-model="errorsDialog" persistent content-class="error-dialog" style="z-index=5">
    <v-card>
      <v-divider></v-divider>
      <v-card-title>
        <div>入札エラー</div>
      </v-card-title>
      <v-card-text class="pt-3 overflow-y-auto">
        <v-row>
          <v-col v-for="(msg, index) in errors" :key="index"  cols="12" class="py-0 error--text subtitle-1">{{ msg }}</v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="indigo white--text" @click="errorsDialog=false">{{$t("ITEM_SEARCH_BULK_BIDDING_MODAL_CANCEL_BUTTON_LABEL")}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <BidConfirmDialog
    :parent="$data"
    :grandParent="parent"
    :confirmBidDialog="confirmBidDialog"
    :bidConfirmItems="bidConfirmItems"
    :bidConfirm="bidConfirm"
    :bidConfirmMessages="bidConfirmMessages"
    :closeBidConfirmDialog="closeBidConfirmDialog"
    :bidConfirmDialogMode="bidConfirmDialogMode"
    :bidConfirmSuccess="bidConfirmSuccess"
    :errors="bidConfirmMessages"
    :bidConfirmSuccessMessage="bidConfirmSuccessMessage"
    :favorite="favorite"
    :getPriceIncludeTax="itemSearchStore.getPriceIncludeTax"
    :getInspectionTxt="itemSearchStore.getInspectionTxt"
  />

</div>
</template>
<script>
import useItemSearch from '@/stores/useItemSearch'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'
import SelectMaker from '../Inspection/parts/SelectMaker'
import ItemRow from './ItemRow'
import BidConfirm from './mixins/BidConfirm.js'
import WebSocket from './mixins/WebSocket.js'
import BidConfirmDialog from './parts/BidConfirmDialog'

export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const itemSearchStore = useItemSearch()
    return {
      loadingStore,
      itemSearchStore
    }
  },
  components : {
    ItemRow,
    BidConfirmDialog,
    SelectMaker
  },
  props : {
    nameTag : {
      type    : String,
      default : null
    },
    parent : {
      type    : Object,
      default : Object
    },
    search : {
      type    : Function,
      default : Object
    },
    printItemsCsv : {
      type    : Function,
      default : Object
    },
    favorite : {
      type    : Function,
      default : Object
    }
  },
  mixins : [WebSocket, BidConfirm],
  data() {
    return {
      errorsDialog     : false,
      selectYears      : Array.from({length : new Date().getFullYear() - 1900}, (value, index) => new Date().getFullYear() - index),
      selectPrice      : Array.from({length : 10}, (value, index) => 100 * (index + 1)),
      selectCubics     : [50, 100, 125, 150, 200, 250, 300, 400, 500, 600, 700, 800, 900, 1000, 2000],
      selectMileages   : Array.from({length : 10}, (value, index) => 10000 * (index + 1)),
    }
  },
  computed : {
    filter() {
      return this.itemSearchStore.filter
    },
    itemsCount() {
      return this.itemSearchStore.itemsCount
    },
    limitPerPages() {
      return this.itemSearchStore.limitPerPages
    },
    isMoreLimit() {
      return this.itemSearchStore.isMoreLimit
    },
    items() {
      return this.itemSearchStore.items
    },
    selectMakers() {
      return this.itemSearchStore.constants.SELECT_MAKER
    },
    selectBodyTypes() {
      return this.itemSearchStore.constants.SELECT_BODY_TYPE
    },
    selectAreas() {
      return this.itemSearchStore.constants.PREFECTURES_CODE
    },
    getFiltered() {
      return this.itemSearchStore.getFiltered(this.itemSearchStore.filteredObject, this.parent.typeTag)
    },
    errors() {
      return this.itemSearchStore.errors
    },
    currentPriceSort() {
      return this.itemSearchStore.filter.currentPriceSort
    }
  },
  methods : {
    categoryExtensionChange() {
      for (let index = 0;index < this.filter.category.length;index++) {
        this.filter.category[index] = this.filter.category[index] || this.filter.categoryExtension[index].filter(element => element).length > 0
      }
      this.filter = Object.assign({}, this.filter)
    },
    categoryChange(index) {
      if (!this.filter.category[index]) {
        this.filter.categoryExtension[index] = []
      }
      this.filter = Object.assign({}, this.filter)
    },
    updateFilter() {
      this.itemSearchStore.updateFilter(this.$router, this.filter)
    },
    btnSearchClick() {
      this.itemSearchStore.filter.initLimit = this.itemSearchStore.filter.limit
      this.itemSearchStore.searchItems(this.$router, null)
    },
    btnMoreClick() {
      this.itemSearchStore.searchItems(this.$router, this.items.map(item => item.exhibition_item_no))
    }
  },
  created() {
    Promise.resolve()
      .then(() => {
        if (this.$route.meta.enableQuery) {
          return this.itemSearchStore.getItemSearchConstants(this.$router)
        } else {
          return this.itemSearchStore.searchItems(this.$router)
        }
      })
  },
  watch : {
    '$i18n.locale'() {
      this.itemSearchStore.searchItems(this.$router)
      this.itemSearchStore.getItemSearchConstants(this.$router, this.$i18n.locale)
    },
    'filter.currentPriceSort'() {
      this.itemSearchStore.searchItems(this.$router)
    },
    'filter.limit'() {
      this.itemSearchStore.searchItems(this.$router)
    },
    '$route.query.filter'(value) {
      try {
        if (value) {
          const newFilter = JSON.parse(decodeURIComponent(value))
          this.itemSearchStore.updateFilter(this.$router, newFilter)
        } else {
          this.itemSearchStore.updateFilter(this.$router, {})
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
})
</script>
<style lang="scss" scoped>

  @media only screen and (max-width: 767px) {
    .error-dialog {
      max-width: 90%;
    }
  }

  @media only screen and (min-width: 768px) {
    .error-dialog {
      max-width: 767px;
    }
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
