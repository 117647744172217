<script>
import {defineComponent} from '@vue/composition-api'

export default defineComponent({
  props : {
    model : {
      type : Number,
    },
    options : {
      type    : Array,
      default : [],
    },
    isError : {
      type    : String,
      default : null
    },
    refTxt : {
      type    : String,
      default : null,
    },
    firstLabel : {
      type    : String,
      default : null,
    },
  },
  data() {
    return {
    }
  },
  computed : {
    isDisabled() {
      return !(
        (this.options.find(x => x.value === this.model) || {}).isTextInput
        === true
      )
    },
    inputVal : {
      get() {
        return this.model
      },
      set(val) {
        this.$emit('onChangeModelValue', val)
      },
    },
  },
  methods : {
  },
})
</script>

<template>
  <select
    v-model="inputVal"
    v-bind:class="{'err': !!isError}"
    ref="maker"
  >
    <option :value="null">{{ typeof firstLabel !== 'undefined' && firstLabel !== null ? firstLabel : $t('INSPECTION_STEP2_FUEL_TYPE_SELECT') }}</option>
    <template v-for="val in (options || [])">
      <template v-if="val.header"><optgroup :label="val.header" :key="`opt_${val.header}`"></optgroup></template>
      <template v-else><option :value="val.value" :key="`opt_${val.value}`"><span class="pl-1">{{'　' + val.label}}</span></option></template>
    </template>
  </select>
</template>
<style scoped lang="scss">
</style>
