<template>
<li v-bind:class="{'new-item': item.is_new}">
  <div class="machine_li-img">
    <a
     class="machine_li-img-wrap com-item-box"
     :class="(confirmBidding && ($route.name == $define.PAGE_NAMES.AUCTION_DETAIL_PAGE_NAME || $route.name == $define.PAGE_NAMES.TENDER_DETAIL_PAGE_NAME)) ? 'unlink' : ''"
     @click="$routerGo(grandfather.detailPageName, item.exhibition_item_no, {filter: itemSearchStore.filter})">
      <img class="item-box-image" v-if="item.images && item.images.length > 0 && item.images.find(file => !file.postar_file_path && file.file_path)"  :src="(item.images.find(file => !file.postar_file_path && file.file_path).file_path) | getFullImagePath"  alt="">
      <img class="item-box-image" v-else src="@/assets/img/no_photo.jpg" alt="">
      <span v-if="item.attention_info" v-bind:class="{'fav-mark': true, 'active': item.attention_info.is_favorited}" @click.stop="favoriteItem(item.attention_info.is_favorited)"></span>
      <img v-if="item.sold_out" class="sold-badge" src="@/assets/img/common/img_soldout_s.png" alt="SOLD OUT">
      <ul class="status">
        <li class="top_status" v-if="item.bid_status && item.bid_status.is_top_member">{{$t("ITEM_ROW_TOP_STATUS_LABEL")}}</li>
        <li class="second_status" v-if="item.bid_status && item.bid_status.is_second_member">{{$t("ITEM_ROW_SECOND_STATUS_LABEL")}}</li>
        <li v-if="item.bid_status && item.bid_status.is_exceeding_lowest_price">{{$t("ITEM_ROW_EXCEEDING_THE_LOWEST_BID_STATUS_LABEL")}}</li>
        <li v-if="item.bid_status && item.bid_status.is_not_exceeding_lowest_price">{{$t("ITEM_ROW_NOT_EXCEEDING_THE_LOWEST_BID_STATUS_LABEL")}}</li>
        <li v-if="item.bid_status && item.bid_status.is_more_little">{{$t("ITEM_ROW_MORE_LITTLE_LABEL")}}</li>
        <li v-if="item.bid_status && item.bid_status.is_cancel">{{$t("ITEM_ROW_CANCEL_LABEL")}}</li>
      </ul>
      <ul v-if="item.item_count && item.item_count > 1" class="item-set">
        <li>{{item.item_count + $t("ITEM_ROW_MULTIPLE_ITEMS_LABEL")}}</li>
      </ul>
    </a>
  </div>
  <div class="machine_li-txt">
    <p class="stock-location" v-if="$route.name !== $define.PAGE_NAMES.STOCK_LIST_PAGE_NAME && (!item.exhibition_member_no && item.exhibition_division === 2)">{{ $t('ITEM_ROW_EXHIBIT_UEJ_LABEL') }}</p>
    <p class="stock-location" v-if="$route.name !== $define.PAGE_NAMES.STOCK_LIST_PAGE_NAME && (item.exhibition_member_no && item.exhibition_division === 2)">{{ $t('ITEM_ROW_EXHIBIT_UEJ_LABEL') }}</p>
    <p class="stock-location" v-if="$route.name !== $define.PAGE_NAMES.STOCK_LIST_PAGE_NAME && (item.exhibition_division === 1)">{{ $t('ITEM_ROW_EXHIBIT_MEMBER_LABEL') }}</p>
    <p v-if="item.free_field.makerName" class="price-system">{{ item.free_field.makerName }}</p>
    <!-- <p class="price-system">{{$t(grandfather.typeTag)}}</p> -->
    <!-- <p v-if="item.is_recommending" class="recommending-status">{{$t("ITEM_SEARCH_RECOMMEND_LABEL")}}</p> -->
    <div class="mach-data">
      <dl>
        <dt>{{$t("ITEM_ROW_BIKE_TYPE_LABEL")}}</dt>
        <dd class="line-break">{{item.free_field.bike_type}}</dd>
      </dl>
      <dl>
        <dt class="mw-5">{{$t("ITEM_ROW_CUBIC_CAPACITY")}}</dt>
        <dd>{{item.free_field.cubic_capacity_other | getCubicText($t('ITEM_FREE_FIELD_FUMEI'))}}</dd>
      </dl>
      <dl>
        <dt>{{$t("ITEM_ROW_INSPECTION_DATE")}}</dt>
        <dd>{{getInspectionTxt(item.free_field.inspec_expire, item.free_field.inspec_expire_other, item.exhibition_item_no)}}</dd>
      </dl>
      <dl>
        <dt class="mw-5">{{$t("ITEM_ROW_MILEAGE")}}</dt>
        <dd class="line-break">{{mileageComma(item.free_field.mileage_other) || $t('ITEM_FREE_FIELD_FUMEI')}}</dd>
      </dl>
    </div>
    <div class="mach-price" v-if="item.bid_status">
      <div class="current-price d-flex flex-column">
        <dl v-if="$route.name==$define.PAGE_NAMES.TENDER_LIST_PAGE_NAME">
          <dt style="width:6em;">{{$t("DETAIL_LOWEST_BID_ACCEPT_PRICE_LABEL")}}</dt>
          <dd v-if="item.sold_out">{{$t("ITEM_ROW_SOLD_OUT_LABEL")}}</dd>
          <dd style="white-space: nowrap;" v-else>{{$t("ITEM_ROW_PRICE_CURRENCY_MARK")}}{{item.bid_status.lowest_bid_price.numberString()}}</dd>
        </dl>
        <dl v-else>
          <dt v-if="item.attention_info.bid_count > 0">{{$t("ITEM_ROW_CURRENT_PRICE_LABEL")}}</dt>
          <dt v-else style="width:6em;">{{$t("ITEM_ROW_START_PRICE_LABEL")}}</dt>
          <dd v-if="item.sold_out">{{$t("ITEM_ROW_SOLD_OUT_LABEL")}}</dd>
          <dd style="white-space: nowrap;" v-else>{{$t("ITEM_ROW_PRICE_CURRENCY_MARK")}}{{item.bid_status.current_price.numberString()}}</dd>
        </dl>
        <div v-if="!item.sold_out" class="bidPrice float-right">
          <span class="bidP-tax">
            （{{$t('ITEM_ROW_PRICE_WITH_TAX_LABEL')}}: {{$t("ITEM_ROW_PRICE_CURRENCY_MARK")}}{{taxPrice(item.bid_status.current_price)}}）
          </span>
        </div>
      </div>
      <div class="count-down-box">
        <p class="fin" v-if="item.bid_status.extending">
          <ExtendCountDown
          :remainingSeconds="item.bid_status.remaining_seconds"
          :endDatetime="item.bid_status.end_datetime"
          :key="item.bid_status.remaining_seconds"/>
        </p>
        <p class="fin" v-else-if="item.bid_status.started">{{item.bid_status.remaining_seconds > 0 ? $t("ITEM_ROW_END_STATUS_LABEL") : $t("ITEM_ROW_ENDED_STATUS_LABEL")}} {{item.end_datetime | moment($t('ROW_BID_DATE_TIME_FORMAT'))}}</p>
        <p class="fin" v-else>{{$t("ITEM_ROW_START_STATUS_LABEL")}} {{item.start_datetime | moment($t('ROW_BID_DATE_TIME_FORMAT'))}}</p>
        <ul class="action-item" v-if="item.attention_info">
          <li v-if="item.attention_info.view_count" class="action-view"><img src="@/assets/img/common/ic_view.svg" alt=""><span>{{item.attention_info.view_count}}</span></li>
          <li v-if="item.attention_info.favorited_count" class="action-fav"><img src="@/assets/img/common/ic_stars.svg" alt=""><span>{{item.attention_info.favorited_count}}</span></li>
          <li v-if="item.attention_info.bid_count" class="action-bid"><img src="@/assets/img/common/ic_check.svg" alt=""><span>{{item.attention_info.bid_count}}</span></li>
        </ul>
      </div>
    </div>
  </div>
  <div class="machine_li-price" v-if="$route.name == $define.PAGE_NAMES.STOCK_LIST_PAGE_NAME">
    <div class="machine_li-price">
      <div class="sale-price">
        <p class="tit-sale">{{$t("ITEM_ROW_SELLING_PRICE_LABEL")}}</p>
        <p class="txt-price" v-if="(item.free_field && item.free_field.SHCR === 'C') || (item.attention_info && item.price_display_flag == 0)">{{item.sold_out ? $t("ITEM_ROW_SOLD_OUT_LABEL") : 'ASK'}}</p>
        <p class="txt-price" v-else>
          <span v-if="item.sold_out" class="current-p">{{$t("ITEM_ROW_SOLD_OUT_LABEL")}}</span>
          <span v-else-if="item.attention_info">{{$t("ITEM_ROW_PRICE_CURRENCY_MARK")}}{{item.free_field.NEWSPRC.numberString()}}</span>
          <span v-else>{{item.free_field.NEWSPRC.numberString()}}</span>
        </p>
      </div>
      <ul class="reaction" v-if="item.attention_info">
        <li v-if="item.attention_info.view_count" class="action-view"><img src="@/assets/img/common/ic_view.svg" alt=""><span>{{item.attention_info.view_count}}</span></li>
        <li v-if="item.attention_info.favorited_count" class="action-fav"><img src="@/assets/img/common/ic_stars.svg" alt=""><span>{{item.attention_info.favorited_count}}</span></li>
        <li v-if="item.attention_info.bid_count" class="action-bid"><img src="@/assets/img/common/ic_check.svg" alt=""><span>{{item.attention_info.bid_count}}</span></li>
      </ul>
    </div>
  </div>
  <div class="machine_li-price" v-else>
    <div class="bidForm" v-if="item.bid_status">
      <v-tooltip top :color="((errors.length > 0 && errors[0].success) ? 'success' : 'error')" v-model="errors.length">
      <template v-slot:activator="{ on, attrs }">
        <div>
          <form v-on:submit.prevent="" v-if="item.bid_status.can_bid || item.sold_out">
            <div class="modal-error-message" v-if="errorMessages.length>0">
              <div v-for="(message,index) in errorMessages" :key="index">{{message.errorMessage}}</div>
            </div>
            <div class="bidPrice">
              <p class="bidP-tit">{{$t("ITEM_ROW_BID_PRICE_LABEL")}}</p>
              <div class="bidP-txt">
                <span class="yen">{{$t("ITEM_ROW_PRICE_CURRENCY_MARK")}}</span>
                <input
                  @focus="errorMessages.splice(0, errorMessages.length);errors=[]"
                  v-bind:class="{'ipt-price': true, 'err': errorMessages.length > 0 || (errors.length > 0 && !errors[0].success)}"
                  v-model="inputBidPrice"
                  type="text"
                  maxlength="5"
                  v-on:keypress="isNumber($event)"
                  v-on:focusin="inputBidPrice = onFocusin_start_price($event)"
                  v-on:focusout="inputBidPrice = onFocusout_start_price($event)"
                 >
                {{item.bid_status.pitch_width.numberString().substring(1)}}
              </div>
            </div>
            <div class="bidP-btn">
              <button v-if="item.bid_status.pitch_button_1" @click="addPitch(item.bid_status.pitch_button_1)">¥{{item.bid_status.pitch_button_1.numberString()}}</button>
              <button v-if="item.bid_status.pitch_button_2" @click="addPitch(item.bid_status.pitch_button_2)">¥{{item.bid_status.pitch_button_2.numberString()}}</button>
              <button v-if="item.bid_status.pitch_button_3" @click="addPitch(item.bid_status.pitch_button_3)">¥{{item.bid_status.pitch_button_3.numberString()}}</button>
            </div>
            <div class="bidP-enter">
              <button @click="bidItemClick(grandfather.typeTag)">{{$t("ITEM_ROW_BID_BUTTON_LABEL")}}</button>
              <a @click="reloadItemStatus" :class="{'btn-reload': true}"><img src="@/assets/img/common/ic_reload.svg" alt=""></a>
            </div>
          </form>
          <div v-if="item.sold_out" class="sold-gray">
            <img src="@/assets/img/common/img_soldout_m.png" alt="SOLD OUT">
          </div>
        </div>
      </template>
      <div :class="errors.length>0 && errors[0].success ? 'custom-tooltip': ''">
        <div style="padding: 10px;" v-if="errors.length>0">
          <div v-for="(message,index) in errors" :key="index">{{message.errorMessage}}</div>
        </div>
      </div>
    </v-tooltip>
    </div>
    <div class="member" v-else>
      <p class="tit-member">{{$t("ITEM_ROW_MEMBERS_ONLY_LABEL")}}</p>
      <a class="btnBsc-Black" @click="$routerGo($define.PAGE_NAMES.LOGIN_PAGE_NAME, null, {next: $route.name, id: item.exhibition_item_no, filter: itemSearchStore.filter})">{{$t("ITEM_ROW_LOGIN_LINK_LABEL")}}<img src="@/assets/img/common/ic_arrow_white.svg" alt=""></a>
      <a class="btnBsc-CoCor btn-entry" @click="$routerGo($define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME)"><span>{{$t('ITEM_ROW_NEW_MEMBER_REGISTRATION_BUTTON_LABEL')}}</span><img src="@/assets/img/common/ic_arrow_white.svg" alt=""></a>
    </div>
  </div>
  <a class="btn-detail" @click="$routerGo(grandfather.detailPageName, item.exhibition_item_no, {filter: itemSearchStore.filter})">{{$t("ITEM_ROW_DETAIL_LINK_LABEL")}}<img src="@/assets/img/common/ic_arrow_white.svg" alt=""></a>
</li>
</template>
<script>
import CommonFilters from '@/common/commonFilters'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'
import RowBid from './mixins/RowBid.js'
import ExtendCountDown from './parts/ExtendCountDown'
export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    return {
      loadingStore,
    }
  },
  components : {
    ExtendCountDown
  },
  props : {
    parent : {
      type    : Object,
      default : Object
    },
    grandfather : {
      type    : Object,
      default : Object
    },
    search : {
      type    : Object,
      default : Object
    },
    item : {
      type    : Object,
      default : Object
    },
    bulkBidding : {
      type    : Boolean,
      default : false
    },
    confirmBidding : {
      type    : Boolean,
      default : false
    },
    bidConfirmSuccess : {
      type    : Boolean,
      default : false
    },
    removeBulkItemByItemNo : {
      type    : Function,
      default : Object
    },
    updateViewAfterBid : {
      type    : Function,
      default : Object
    },
    errorMessages : {
      type    : Array,
      default : () => []
    },
    favorite : {
      type    : Function,
      default : Object
    },
    getPriceIncludeTax : {
      type    : Function,
      default : Object
    },
  },
  mixins : [RowBid, CommonFilters],
  data() {
    return {
      errors        : [],
      // InputBidPrice : this.getBidPrice()
    }
  },
  methods : {
    mileageComma(val) {
      if (val) return this.$options.filters.number2string(val, null, 'km')
      else return null
    },
    taxPrice(price) {
      // 会員出品の場合はTaxしない
      if (this.item.exhibition_division === 1) {
        return price ? price.numberString() : '0'
      }
      return price ? this.getPriceIncludeTax(price) : '0'
    }
  },
  watch : {
    inputBidPrice(value) {
      this.item.inputBidPrice = value
      if (value * this.item.bid_status.pitch_width !== this.item.bid_status.bid_price) {
        this.item.changed = true
      }
    },
  }
})
</script>
<style lang="scss">
@media only screen and (max-width: 767px) {
  .item-box-image {
    min-height: 200px;
    min-width: 270px;
  }
}
@media only screen and (min-width: 768px) {
  .item-box-image {
    min-height: 200px;
    min-width: 270px;
  }
}
.btn-detail.background-red {
  background-color: #ce0000 !important;
}
.bidForm .bidP-btn button {
  line-height: 14px;
}
.modal-error-message {
  margin-bottom: 10px !important;
  margin-left: -15px !important;
  border-radius: 5px !important;
  margin-right: -15px !important;
  color: white;
  padding: 5px !important;
  background-color: #ff5252 !important;
  border-color: #ff5252 !important;
}
.status .top_status {
  color: #fff !important;
  background-color: #E80000 !important;
  text-align: center !important;
  flex-grow: 2;
}
</style>
<style scoped lang="scss">
.txt-price {
  span {
    font-weight: 700;
  }
}
.recommending-status {
  padding: 5px 13px;
  font-size: 14px;
  color: #fff;
  background-color: #E80000;
  text-align: center;
  border-radius: 100vh;
  font-weight: 700;
  margin-left: 5px;
  letter-spacing: 0;
  height: 32px;
}
.mach-no {
  height: 32px;
  line-height: 22px !important;
}
.price-system {
  height: 32px;
  line-height: 28px !important;
  margin-right: 5px;
}
.mw90 {
  min-width: 90px !important;
}
</style>
<style scoped lang="scss">
  ul.machine_list > li .machine_li-txt .mach-price dl dd {
    font-size: 22px;
  }
  ul.machine_list > li .machine_li-txt .mach-data dl dd.size-up {
    font-size: 22px;
  }
  .machine_li-img span.fav-mark {
    display: block;
    position: relative;
    top: -190px;
    right: -230px;
    background: url("../../assets/img/common/ic_star_white.svg") center / cover no-repeat;
    width: 25px;
    height: 25px;
  }
  .machine_li-img span.fav-mark.active {
    background-image: url("../../assets/img/common/ic_star_yellow.svg");
  }
  .machine_li-img span.fav-mark:hover {
    cursor: pointer;
  }
</style>
<style scoped lang="scss">
  .btn-reload {
    float: right;
    margin: 0 0;
    text-align: right;
    width: 20%;
    height: 60px;
    img {
      width: 35px;
      height: 100%;
      vertical-align: middle;
    }
  }
  #main #result ul.machine_list > li .machine_li-price .bidForm .bidP-enter button {
    width: 80%;
    background: #fff url("../../assets/img/common/ic_arrow_black.svg") center right 50px / 19px auto no-repeat;
  }
</style>
<style scoped lang="scss">
  .lbl-price {
    font-size: 32px;
    font-weight: 700;
    margin-left: 10px;
    margin-right: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .unlink {
    cursor: inherit;
  }
</style>
<style scoped lang="scss">
  ul.machine_list > li .machine_li-img .machine_li-img-wrap ul.item-set {
    position: absolute;
    left: auto;
    right: 10px;
    bottom: 10px;
  }
  ul.machine_list > li .machine_li-img .machine_li-img-wrap ul.item-set li {
    font-size: 14px;
    font-weight: 700;
    color: #364A81;
    text-align: center;
    padding: 2px 10px;
    background-color: #EAEAEA;
    border-radius: 100vh;
    margin-top: 5px;
  }
</style>
<style scoped lang="scss">
  .status .second_status {
    color: #000 !important;
    background-color: #ced2d8 !important;
    text-align: center !important;
    flex-grow: 2;
  }
</style>
<style scoped lang="scss">
  ul.machine_list > li .machine_li-txt .mach-data dl dd.line-break {
    line-break: anywhere !important;
  }
  .count-down-box {
    position: relative;
    margin-left: 10px;
  }
  .mw-5 {
    min-width: 5em !important;
  }
  .bidP-tax {
    font-size: 0.9em;
    font-weight: 550;
  }
  .current-price {
    width: 60%;
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
