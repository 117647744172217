<template>
<main id="main" class="stock">
  <ItemSearch :parent="$data" :search="searchStockItems" :printItemsCsv="printItemsCsv" :favorite="favorite"/>
</main>
</template>

<script>
import Methods from '@/api/methods'
import ItemSearch from '../../components/ItemList/ItemSearch'
export default {
  components : {
    ItemSearch
  },
  data() {
    return {
      nameTag        : 'STOCK_PAGE_SEARCH_HEADER',
      typeTag        : 'STOCK_PAGE_SEARCH_TYPE',
      detailPageName : this.$define.PAGE_NAMES.STOCK_DETAIL_PAGE_NAME
    }
  },
  methods : {
    searchStockItems(params) {
      return Methods.apiExcute('public/search-stock-items', this.$i18n.locale, params)
    },
    printItemsCsv(params) {
      return Methods.apiExcute('private/print-stock-items-csv', this.$i18n.locale, params)
    },
    favorite(params) {
      return Methods.apiExcute('private/favorite-stock', this.$i18n.locale, params)
    }
  }
}
</script>

<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
